@import '../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filtersWrapper {
}

.footer {
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
}

.filterButton {
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-block;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--colorGrey300);
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  transition: var(--transitionStyle);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
    text-decoration: none;
    border-color: var(--colorBlack);
    transition: var(--transitionStyle);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--colorWhite);
  border: solid 1px var(--colorGrey700);
  background-color: var(--colorGrey700);
  transition: var(--transitionStyle);

  /* Layout */
  margin: 0 12px 0 12px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
    border-color: var(--colorBlack);
    transition: var(--transitionStyle);
    background-color: var(--colorBlack);
  }
}

.applyButton {
  composes: filterButton;
  color: var(--colorWhite);
  border: solid 1px var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  transition: var(--transitionStyle);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorWhite);
    border: solid 1px var(--marketplaceColorDark);
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
    transition: var(--transitionStyle);
  }
}
