@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorWhite);
  position: relative;
  padding: 10px 20px;
}

.filters {
  width: 100%;
  & > * {
    width: 100%;
    margin: 0;
  }
  & > *:not(:last-child) {
    height: auto;
  }
}
